.container.workex__container{
    width: 40%;
    padding-bottom: 4rem;
}

.workex__logo {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.workex {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.workex__work {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-paginstion-bullet {
 background: var(--color-primary);
}

/*============== For Medium Devices==============*/
@media screen and   (max-width: 1024px) {

    .container.workex__container{
    width: 60%;
}
}
/*============== For Phones ==============*/
@media screen and   (max-width: 600px) {
    .container.workex__container{
        width: var(--container-width-sm);
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .workex__work {
        width: var(--container-width-sm);

    }
}