.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}
.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    opacity: 0.7;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: center;
}
.about__me:hover{
    opacity: 1;
}
.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}
.about__me-image .under {
   position: absolute;
   z-index: -1;
   
}
.about__me-image .overlay {
    transition: var(--transition);
}
.about__me-image .overlay:hover{
    opacity: 0;

}

.about__me-image:hover {
    transform: rotate(0deg) ;
}

.about__hero h2 {
    color: var(--color-primary);
    font-size: 0.95rem;
    margin-top: 2rem;
    text-align: center;

}

.about__cards {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    gap: 1.5rem;
}
.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}
.about__card:hover{
    background: transparent;
    border-color: var(--color-primary);
}
.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.about__card h5 {
    font-size: 0.95rem;
}
.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}
.about__content p{
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

@media screen and (min-width: 250px) and (max-width: 900px) {
.about__container{
    grid-template-columns: 1fr;
    gap: 0;
    height: 90%;
}
.about__me {
    width: 65%;
    margin: 0 auto 2rem;

}
.about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    height: 100%;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}
.about__content{
    text-align: center;
}
.about__card{
    height: 90%;
    font-size: 80%;
}
.about__hero h2 {
    color: var(--color-primary);
    font-size: 80%;
    margin-top: 0rem;
    text-align: center;

}

}
@media screen and (min-width: 1461px) {
    .about__card{
        height: 95%;
    }
    
}