header{
    height: 100vh;
    /* padding-top: 2rem; */
    /* overflow: hidden; */
}

.container.header__container .text-light{
     margin-top: -2rem;
}


.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*===============CTA Buttons===============*/

.cta {
    margin-top: 1rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*==================Header Socials and Email=================*/
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0.5rem;
    bottom: 0rem;

}
a #social__icon{
    size: 48;
}
.header__socials::after {
    content: "";
    width: 1px;
    height: 24rem;
    background: var(--color-primary);
}
.container.header__container h5{
    font-size: 1.5rem;
}
.container.header__container h5.text-light{
    font-size: 1.5rem;
}
.header__email {
    display: flex;
    writing-mode: vertical-rl;
    align-items: center;
    position: absolute;
    right: 0;
    top: -7rem;
}
.header__email::before {
    content: "";
    width: 1px;
    height: 20rem;
    background: var(--color-primary);
}

/*===================ME========================*/
.me {
    background: linear-gradient(var(--color-primary), transparent );
    width: 22rem;
    height: 30rem;
    /* min-height: ; */
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 1rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5em 1.5rem 1.5rem;
}
.me img{
    transform: scaleX(-1);
}
.scroll__down{
    position: absolute;
    right: -7.3rem;
    bottom: 2rem;
    font-weight: 300;
    font-size: 0.9rem;
}
.text-light.styles_typicalWrapper__1_Uvh{
    font-size: 1.8rem;
}


@media screen and (max-width: 600px) {
    .header__email{
        display: none;
    }
    .header__socials {
        left: -1rem;
        bottom: 0rem;
    }
    .scroll__down{
        color: var(--color-primary);
        position: absolute;
        right: 0.3rem;
        bottom: 0rem;
        font-weight: 300;
        font-size: 0.9rem;
    }
    .text-light.styles_typicalWrapper__1_Uvh{
        font-size: 1rem;
    }

}
@media screen and (min-width: 600px) and (max-width: 1024px) {
    .header__email{
        right: 0rem;
    }
    .scroll__down{
        color: var(--color-primary);
        position: absolute;
        right: 0.3rem;
        bottom: 0rem;
        font-weight: 300;
        font-size: 0.9rem;
    }

}

@media screen and (max-height: 700px){
    header{
        height: 125vh;
        padding-top: 12.5vh;
        overflow: hidden;
    }
    .header__socials {
        bottom:0.5rem;
    }
    .header__socials::after {
        content: "";
        width: 1px;
        height: 15rem;
        background: var(--color-primary);
    }


}
@media screen and (max-width: 361px ) {

    header{
        height: 125vh;
    }
    .text-light.styles_typicalWrapper__1_Uvh{
        font-size: 1rem;
    }
}