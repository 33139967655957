footer{
    background: var(--color-bg-variant);
    padding: 2rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-primary);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 0.5rem;
    padding: 0.3rem;
    display: inline-block;
}

@media screen and (max-width: 600px) {
    .footer__copyright{
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 90%;

    }
}