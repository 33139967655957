.services__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.services__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);

}
.container.services__container{
    margin-top: 2rem;
}
#toolkit > h2{
    margin-top: 2rem;
    
}
.services__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.services__container > div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);

}
.services__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
.services__details{
    display: flex;
    gap: 1rem;
}
.services__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: 1fr;
    }
    .services__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .services__content {
        padding: 1rem;
    }

}

@media screen and (max-width: 600px) {
    .services__container {
        gap: 1rem;
        padding: 1rem;
    }
    .services__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }

}